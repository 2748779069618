import Link from 'next/link'
import styled, { css } from 'styled-components'

import theme from '../../theme'
import Media from '../../utils/Media'

type Color = 'dark' | 'light'
type Size = 'small' | 'large'

interface Props {
  color?: Color
  size?: Size
  $showIcon?: boolean
}

const renderArrowIcon = () => css`
  &::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 50%;
    right: 34px;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    transform: translate(0, -50%) rotate(45deg);
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
  }
`

const renderColorStyle = (color?: Color) => {
  const defaultColor =
    color === 'light' ? theme.colors.primary : theme.colors.white
  const invertColor =
    color === 'light' ? theme.colors.white : theme.colors.primary

  return css`
    border: 2px solid ${invertColor};
    background: ${invertColor};
    color: ${defaultColor};

    &:hover {
      background: ${defaultColor};
      color: ${invertColor};

      &::after {
        border-color: ${invertColor};
      }
    }
  `
}

const Button = styled(Link)<Props>`
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  min-height: 70px;
  padding: 12px 12px;
  transition:
    background 0.2s,
    color 0.2s,
    fill 0.2s;
  border-radius: 10px;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.75;
  text-align: center;
  text-decoration: none;
  ${(props) => props.$showIcon !== false && renderArrowIcon()};
  ${(props) => renderColorStyle(props.color)}

  ${Media.desktop} {
    min-height: ${(props) => (props.size === 'small' ? 45 : 70)}px;
    padding: 8px 20px;
  }

  ${Media.mobileMiddle} {
    font-size: 20px;
  }
`

export default Button
