import React from 'react'
// import styled from 'styled-components'
import styled, { css } from 'styled-components'

import theme from '../../theme'
import { RydeIncWebErrorType } from '../../utils/Errors'
import Button from './Button'
import { ContainerProps, ContainerStyle } from './Container'
import Section from './Section'
import Title from './Title'

interface ErrorMessage {
  title: string
  description: string
}

interface Props {
  type?: RydeIncWebErrorType
}

const getMessage = (type: RydeIncWebErrorType): ErrorMessage => {
  switch (type) {
    case RydeIncWebErrorType.NotFound:
      return {
        title: 'お探しのページが見つかりません',
        description:
          'RYDE（ライド）をご利用いただきありがとうございます。<br/>お探しのページは移動・削除されたか、入力したURLに誤りがある可能性があります。',
      }
    case RydeIncWebErrorType.ServerError:
      return {
        title: 'ページが表示できません',
        description:
          'ご不便をおかけし申し訳ございません。一時的なエラーが発生いたしました。<br/>正常にご覧いただけるよう、解決に取り組んでおります。対応完了までしばらくお待ち下さい。',
      }
    case RydeIncWebErrorType.NetworkError:
      return {
        title: 'ページが表示できません',
        description:
          'ご不便をおかけし申し訳ございません。一時的なエラーが発生いたしました。<br/>正常にご覧いただけるよう、解決に取り組んでおります。対応完了までしばらくお待ち下さい。',
      }
    default:
      return {
        title: 'ページが表示できません',
        description:
          'ご不便をおかけし申し訳ございません。不明なエラーが発生いたしました。<br/>正常にご覧いただけるよう、解決に取り組んでおります。対応完了までしばらくお待ち下さい。',
      }
  }
}

const ErrorView: React.FC<Props> = ({
  type = RydeIncWebErrorType.Unknown,
}: Props) => {
  const message = getMessage(type)
  const { title, description } = message

  return (
    <Wrapper>
      <Section>
        <Title $padded={true}>{title}</Title>
        <Text dangerouslySetInnerHTML={{ __html: description }} />
        <Centered>
          <Button href="/" $showIcon={false} passHref aria-label="RYDE">
            TOPページへ
          </Button>
        </Centered>
      </Section>
    </Wrapper>
  )
}

const Text = styled.p`
  ${theme.typography.Body3}
  text-align: center;
`

const Wrapper = styled.div<ContainerProps>`
  padding-top: 20px;
  padding-bottom: 20px;
  ${() => css`
    ${ContainerStyle}
  `}
`

const Centered = styled.div`
  margin-top: 60px;
  text-align: center;
`

export default ErrorView
