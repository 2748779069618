import { ApolloError } from 'apollo-client'

enum RydeIncWebErrorType {
  NotFound = 'notFound',
  ServerError = 'serverError',
  NetworkError = 'networKError',
  Unknown = 'unknown',
}

const convertToErrorType = (apolloError: ApolloError): RydeIncWebErrorType => {
  if (apolloError.networkError) {
    return RydeIncWebErrorType.NetworkError
  }

  return RydeIncWebErrorType.Unknown
}

export { convertToErrorType, RydeIncWebErrorType }
