import { NextPage, NextPageContext } from 'next'
import React from 'react'

import ErrorView from '../components/shared/ErrorView'
import { RydeIncWebErrorType } from '../utils/Errors'

// production時(next buildの成果物を使っている時)のエラー表示に使われる
// https://nextjs.org/docs/advanced-features/custom-error-page

interface Props {
  statusCode: number
}
const Error: NextPage<Props> = ({ statusCode }) => {
  switch (statusCode) {
    case 400:
      ;<ErrorView type={RydeIncWebErrorType.NotFound} />
      break
    case 404:
    case 405:
      ;<ErrorView type={RydeIncWebErrorType.NotFound} />
      break
    case 500:
      ;<ErrorView type={RydeIncWebErrorType.ServerError} />
      break
    default:
      return <ErrorView type={RydeIncWebErrorType.NotFound} />
  }
  return <ErrorView type={RydeIncWebErrorType.NotFound} />
}

Error.getInitialProps = async ({ res, err }: NextPageContext) => {
  // statusCodeを算出する。
  // - resが存在する時はSSRであり、res.statusCodeをそのまま利用すれば良い。
  // - resがない場合はCSRである。
  //   - err.statusCodeがあればそれをそのまま利用する
  //   - 意図しない例外が起きてerrがここに渡ってくる場合、単なるErrorオブジェクトが入っていてstatusCodeプロパティがない。errがある時点でISEなので500にする
  // See Also: https://nextjs.org/docs/advanced-features/custom-error-page
  // const statusCode = res ? res.statusCode : (err ? (err.statusCode ?? 500) : 404)
  let statusCode: number
  if (res) {
    statusCode = res.statusCode
  } else if (err) {
    statusCode = err.statusCode ?? 500
  } else {
    statusCode = 404
  }
  return { statusCode }
}

export default Error
