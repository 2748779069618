import styled, { css } from 'styled-components'

import theme from '../../theme'
import Media from '../../utils/Media'

interface Props {
  color?: 'dark' | 'light'
  $padded?: boolean
}

const toRGB = (color: 'dark' | 'light' | undefined) =>
  color === 'dark' ? theme.colors.brand : theme.colors.white

const Title = styled.h3<Props>`
  ${theme.typography.Headline1}
  ${(props) =>
    props.$padded &&
    css`
      margin: 30px auto 20px;

      ${Media.desktop} {
        margin: 30px auto 30;
      }
    `}
  color: ${(p) => toRGB(p.color)};
  line-height: 1.25;
  text-align: center;
  white-space: pre-wrap;

  &::after {
    content: '';
    display: block;
    width: 56px;
    height: 4px;
    margin: 10px auto 0;
    background-image: linear-gradient(
      to right,
      ${(p) => toRGB(p.color)} 8px,
      transparent 8px
    );
    background-size: 12px 100%;

    ${Media.desktop} {
      content: '';
      display: block;
      width: 112px;
      height: 8px;
      margin: 18px auto 0;
      background-image: linear-gradient(
        to right,
        ${(p) => toRGB(p.color)} 16px,
        transparent 16px
      );
      background-size: 24px 100%;
    }
  }
`

Title.defaultProps = {
  color: 'dark',
  $padded: false,
}

export default Title
