import styled from 'styled-components'

import Media from '../../utils/Media'

type Props = {
  topPadded?: boolean
  bottomPadded?: boolean
}

const Section = styled.section<Props>`
  padding-top: ${(p) => (p.topPadded === false ? 0 : 96)}px;
  padding-right: 0;
  padding-bottom: ${(p) => (p.bottomPadded === false ? 0 : 96)}px;
  padding-left: 0;

  ${Media.mobileTablet} {
    padding-top: ${(p) => (p.topPadded === false ? 0 : 88)}px;
    padding-bottom: ${(p) => (p.bottomPadded === false ? 0 : 56)}px;
  }
`

export default Section
